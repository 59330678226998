import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="reports--import-modal"
export default class extends Controller {
  static targets = ["name", "importButton", "invalidFileMessage"];

  async uploadedFile(ev) {
    this.nameTarget.value = "";
    this.nameTarget.disabled = true;
    this.importButtonTarget.disabled = true;
    this.invalidFileMessageTarget.classList.add("d-none");

    if (ev.target.files.length !== 1) return;
    const file = ev.target.files[0];

    try {
      const json = await new Response(file).json();
      this.nameTarget.value = json["name"];
      this.nameTarget.disabled = false;
      this.importButtonTarget.disabled = false;
    } catch (err) {
      console.warn(`File could not be parsed: ${err}`);
      this.invalidFileMessageTarget.classList.remove("d-none");
    }
  }
}
