import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static get targets() {
    return [
      "filterForm",
      "filterSubmit"
    ]
  }

  submitForm(){
    // replace submit button with a spinner and submit the form
    const loader = document.createElement('button');
    loader.disabled = true;
    loader.classList.add('btn', 'btn-primary', 'mr-2');

    const spinner = document.createElement('span');
    spinner.classList.add('spinner-border', 'spinner-border-sm');
    loader.append(spinner);

    this.filterSubmitTarget.replaceWith(loader);
    
    this.filterFormTarget.requestSubmit()
  }
}
